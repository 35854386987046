import React from "react"
import { graphql } from "gatsby"

import PageComponent from "components/PageComponent"

const Page = ({ data }) => {
  return <PageComponent page={data} />
}

export default Page

export const query = graphql`
  query PageBySlug($slug: String!) {
    contentfulPage(slug: { eq: $slug }) {
      ...Page
    }
  }
`
